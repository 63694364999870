/**
 * lit-element implementation of MDCDrawer component
 *  customized as common navigation menu for site
 * @license MIT
 * Copyright 2023 Four Mortals Productions, Inc.
 * @version 24.1.0
 * Module history:
 *    1.0.0 - Initial build
 *   14.4.5 - Completely rewritten
 *   24.1.0 - Use @material/web components
 * @since September 2020
 * @author Earl Rogers
 */

'use strict';

import {LitElement, html, nothing, unsafeCSS} from 'lit';
import style from './_index.scss';
import {classMap} from 'lit/directives/class-map.js';
// import {ifDefined} from 'lit/directives/if-defined.js';
// import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {getListItem} from '@web/list/component.js';
// import {strings as constants} from './constants.js';
export class NavDrawer extends LitElement {
  static get styles() {
    return unsafeCSS(style);
  }
  static get properties() {
    return {
      opened: {type: Boolean, attribute: false},
      items: {type: Array, attribute: false},
      selected: {type: String},
    };
  }
  static get items() {
    return [
    // {divider: true},
      // eslint-disable-next-line max-len
      {href: '/stlcards/', label: 'St Louis Cardinals', icon: 'sports_baseball'},
      {href: '/', label: 'Home', icon: 'home'},
    ];
  }

  static get bbq() {
    return [
      {href: '/bbq/#recipes', label: 'Recipes', icon: 'outdoor_grill'},
      {href: '/bbq/#rubs', label: 'Rubs', icon: 'outdoor_grill'},
      {href: '/bbq/#sauces', label: 'Sauces', icon: 'outdoor_grill'},
      {href: '/bbq/#marinades', label: 'Marinades', icon: 'outdoor_grill'},
      {href: '/bbq/#yoder', label: 'My Smoker', icon: 'outdoor_grill'},
      {href: '/bbq/', label: 'Home', icon: 'outdoor_grill'},
    ];
  }
  static get pets() {
    return [
      {href: '/pets/doxies/', label: 'Russell & Freckles', icon: 'pets'},
      {href: '/pets/piglet/', label: 'Piglet', icon: 'pets'},
      {href: '/pets/tasha/', label: 'Tasha', icon: 'pets'},
      {href: '/pets/silk/', label: 'Silk', icon: 'pets'},
      {href: '/pets/ff/', label: 'Friends & Family', icon: 'pets'},
      {href: '/pets/', label: 'Home', icon: 'pets'},
    ];
  }

  constructor() {
    super();
  }

  firstUpdated(changedProperties) {
    document.addEventListener('TopAppBar:nav', () => {
      this.opened = true;
    });
    this.closeIcon = this.shadowRoot.querySelector('#close-icon');
    this.closeIcon.addEventListener('click', (event) => {
      this.opened = !this.opened;
    });
    /*
    this.listItems = [...this.shadowRoot.querySelectorAll('md-list-item')]
        .filter((el) => el.firstElementChild.localName !== 'details');
    */
    window.addEventListener('hashchange', (e)=>{
      this.opened = false;
      this.selected = e.currentTarget.href;
    });
  }

  getListItem(props) {
    let hash = undefined;
    if (location.hash) {
      hash = location.hash.indexOf('?') === -1 ?
      location.hash :
      location.hash.substring(0, location.hash.indexOf('?'));
    }
    const path = hash ?
      location.pathname + hash : location.pathname;
    if (path === props?.href) {
      props.disabled = true;
      props.type = 'text';
      delete props.href;
    }
    return getListItem(props);
  }

  getRenderClasses() {
    return classMap({
      'nav--opened': this.opened,
    });
  }

  getScrimClasses() {
    return classMap({
      'nav__scrim-visible': this.opened,
    });
  }

  handleKeyDown(event) {
    if (event.code === 'Escape') {
      this.opened = false;
    }
  }

  handleScrimClick() {
    this.opened = !this.opened;
  }

  render() {
    const items = [...this.constructor.items];
    const bbq = [...this.constructor.bbq];
    const pets = [...this.constructor.pets];
    const ariaExpanded = this.opened ? 'true' : 'false';
    const ariaHidden = !this.opened ? 'true' : 'false';
    // Needed for closure conformance
    const {ariaLabel, ariaModal} = this;
    const isBbqOpen = location.pathname === '/bbq/';
    const isPetsOpen = location.pathname.startsWith('/pets');
    /* eslint-disable max-len, indent */
    return html`
    <div
        class="nav__scrim ${this.getScrimClasses()}"
        @click="${this.handleScrimClick}">
      </div>
    <nav class="nav ${this.getRenderClasses()}"
        aria-expanded=${ariaExpanded}
        aria-hidden=${ariaHidden}
        aria-label=${ariaLabel || nothing}
        aria-modal=${ariaModal || nothing}
        @keydown="${this.handleKeyDown}">
      <div class="nav__closebtn-container">
        <md-icon-button id="close-icon" role="presentation">
          <md-icon aria-hidden="true">close</md-icon>
        </md-icon-button>
      </div>
      <div class="nav__slot-content">
        <md-list class="nav-list"> 
        <md-list-item>
          <details id="bbq" ?open="${isBbqOpen}">
            <summary>BBQ</summary>
            <md-list class="nav-list">
            ${bbq.map((item, index) => html `
            ${this.getListItem(item)}
          `)}
            </md-list>
          </details>
        </md-list-item>
        <md-list-item>
        <details id="pets" ?open="${isPetsOpen}">
          <summary>Pets</summary>
          <md-list class="nav-list">
          ${pets.map((item, index) => html `
            ${this.getListItem(item)}
          `)}
          </md-list>
        </details>
      </md-list-item>
        ${items.map((item, index) => html `
            ${this.getListItem(item)}
        `)}
        </md-list>
      </div>
    </nav>
    `;
    /* eslint-enable max-len */
  }

  updated(changedProperties) {
    if (changedProperties.has('opened')) {
        setTimeout(() => {
            this.dispatchEvent(new CustomEvent(this.constructor.changedEvent, {
                detail: {opened: this.opened},
                bubbles: true,
                composed: true,
            }));
        }, 250);
    }
  }
}

// Register the new element with the browser.
customElements.define('ear-nav-drawer', NavDrawer);
