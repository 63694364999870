/**
 *  lit-element implementation of MDCTopAppBar component
 *  customized for site
 * @license MIT
 * Copyright 2023 Four Mortals Productions, Inc.
 * @version 24.1.0
 * Module history:
 *    3.0.0 - Initial build (port from #fourmortals/material)
 *   13.0.0 - @material 13.0, lit 2.0
 *   13.1.0 - Add signin functionality
 *   14.4.5 - Completely rewritten
 *   24.1.0 - Use @material/web components
 * @since January 2021
 * @author Earl Rogers
 */

'use strict';

import {LitElement, html, nothing, unsafeCSS} from 'lit';
// import {classMap} from 'lit/directives/class-map.js';
import style from './_index.scss';
import {credentialsFromToken, tokenFromCookie}
  from '@fourmortals/login/util.js';
import {events} from '@fourmortals/login/constants.js';
// eslint-disable-next-line no-unused-vars
import {FMPAuthenticate}
  from '@fourmortals/login/dialogs/authenticate/element.js';
// eslint-disable-next-line no-unused-vars
import {FMPSignout}
  from '@fourmortals/login/dialogs/signout/element.js';
import {emit} from '@fourmortals/events';

export class TopAppBar extends LitElement {
  static get styles() {
    return unsafeCSS(style);
  }

  static get properties() {
    return {
      credentials: {type: Object, attribute: false,
        hasChanged(newVal, oldVal) {
          return newVal?.username !== oldVal?.username;
        }},
      maintLink: {type: String, attribute: false},
      tokenCookie: {type: Boolean, atttribute: false},
    };
  }

  static get editablePages() {
    return ['/bbq', '/blog'];
  }

  constructor() {
    super();
    const theme = localStorage.getItem('ear-theme');
    if (theme) {
      this.isDarkMode = theme === 'dark';
    } else {
      this.isDarkMode = window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    /*
    Already done in /styles.html
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.add('light');
    }
    */
    this.credentials = null;
    this.maintLink = this.isEditable ? '#maint' : undefined;
    this.tokenCookie_ = tokenFromCookie();
    if (this.tokenCookie_) {
      this.credentials = credentialsFromToken(this.tokenCookie_);
    }
    if (location.hostname === 'localhost') {
      console.log(this.credentials);
    }
  }

  get isEditable() {
    for (const page of this.constructor.editablePages) {
      if (location.pathname.startsWith(page)) {
        if (location.hash?.indexOf('id') !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  firstUpdated(changedProperties) {
    /*
    const sel = '.button, ';
    [...this.shadowRoot
        .querySelectorAll(sel)].map((el) => {
    });
    */
    const toggle = this.shadowRoot.querySelector('#mode-toggle');
    toggle.addEventListener('click', (e) => {
      const current = this.isDarkMode ? 'dark' : 'light';
      const tgt = current === 'light' ? 'dark' : 'light';
      this.isDarkMode = !this.isDarkMode;
      document.documentElement.classList.remove(current);
      document.documentElement.classList.add(tgt);
      localStorage.setItem('ear-theme', tgt);
    });

    let el = this.shadowRoot.querySelector('.logo-container');
    el.addEventListener('click', (event) => {
      if (location.pathname !== '/') {
        location.href = '/';
      } else {
        if (location.hash) {
          location.hash = '';
        }
      }
    });

    const menuBtn = this.shadowRoot.querySelector('#open-nav');
    menuBtn.addEventListener('click', (event) => {
      emit('TopAppBar:nav', {});
    });

    document.addEventListener(events.LOGIN_COMPLETE, (event) => {
      this.credentials = event.detail;
    });

    document.addEventListener(events.LOGOUT_COMPLETE, (event) => {
      this.credentials = null;
    });

    if (location.pathname.startsWith('/bbq')) {
      window.addEventListener('hashchange', (event) => {
        if (location.hash.startsWith('#product') ||
            location.hash.startsWith('#maint')) {
          this.maintLink = location.hash.replace('product', 'maint');
        }
      });
    }
    el = document.querySelector('body>main');
    el.classList.remove('theme-invisable');
    // el.classList.add('theme-visible');
  }

  signin() {
    if (this.credentials) {
      emit('FMPSignout:show', this.credentials);
    } else {
      emit('FMPAuthenticate:show', {});
    }
  }

  /*
  async signout() {
    const url = `${location.origin}/users/router.php`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'logoff': true,
      }),
    });
    const json = await response.json();
    if (location.hostname === 'localhost') {
      console.log(json);
    }
    if (json.rc === 0) {
      if (location.pathname !== '/') {
        location.href = '/';
      } else {
        this.tokenCookie_ = tokenFromCookie();
        if (this.tokenCookie_) {
          this.credentials = credentialsFromToken(this.tokenCookie_);
        }
      }
    }
  }
 */

  get tokenCookie() {
    return this.tokenCookie_;
  }

  set tokenCookie(newVal) {
    const oldVal = this.tokenCookie_;
    this.tokenCookie_ = newVal;
    this.requestUpdate('tokenCookie', oldVal);
  }

  getModeButton() {
    const selectedIcon = this.isDarkMode ? 'dark_mode' : 'light_mode';
    const unselectedIcon = !this.isDarkMode ? 'dark_mode' : 'light_mode';
    return html `
        <md-icon-button toggle selected
          id="mode-toggle" aria-label="Unselected"
          aria-label-selected="Selected"
          title="Toggle light/dark mode">
        <md-icon slot="selected">${selectedIcon}</md-icon>
        <md-icon>${unselectedIcon}</md-icon>
      </md-icon-button>
    `;
  }

  getSigninButton() {
    const msg = this.credentials ?
        `Hello ${this.credentials.username}. Click to signout.` :
        'Click to signin';

    const selectedIcon = this.credentials ? 'logout' : 'login';
    const unselectedIcon = !this.credentials ? 'login' : 'logout';
    return html `
        <md-icon-button toggle selected
          @click="${this.signin}"
          id="signin-toggle" aria-label="Unselected"
          aria-label-selected="Selected"
          title="${msg}">
        <md-icon slot="selected">${selectedIcon}</md-icon>
        <md-icon>${unselectedIcon}</md-icon>
      </md-icon-button>
    `;
  }


  render() {
    /* eslint-disable max-len, indent*/
    return html`
    <nav class="top-app-bar">
      <div class="top-app-bar__row">
        <section class="top-app-bar__section top-app-bar__section--align-start">
          <div class="logo-container">
            <img src="/images/earl.sailing.96x96.jpg"/>
          </div>
        </section>
        <section class="top-app-bar__section top-app-bar__section--align-end" role="toolbar">
            <md-icon-button href="/bbq/">
                <md-icon>outdoor_grill</md-icon>
            </md-icon-button>
            <md-icon-button href="/pets/">
                <md-icon>pets</md-icon>
            </md-icon-button>      
            <md-icon-button href="/stlcards/">
                <md-icon>sports_baseball</md-icon>
            </md-icon-button>      

          ${this.credentials?.username && this.isEditable ?
            html`
              <md-icon-button href="${this.maintLink}">
                  <md-icon>create</md-icon>
              </md-icon-button>      
            ` : nothing}  

          ${this.getSigninButton()}
          ${this.getModeButton()}

          ${location.pathname !== '/' ?
            html`
            <md-icon-button href="/">
                <md-icon>home</md-icon>
            </md-icon-button>      
            ` : nothing}
            
            <md-icon-button id="open-nav">
                <md-icon>menu</md-icon>
            </md-icon-button>
        </section>
      </div>
    </nav>
    <fmp-authenticate></fmp-authenticate>
    <fmp-signout></fmp-signout>
  `;
  /* eslint-enable max-len, indent */
  }

  updated(changedProperties) {
  }
}
// Register the new element with the browser.
customElements.define('ear-top-app-bar', TopAppBar);
